<template>
    <div class="product">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li><router-link to="/products">Products</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">{{ product.name === "" ? 'New Product' : product.name }}</a></li>
            </ul>
        </nav>

        <fieldset :disabled="isLoading">

            <div class="field is-grouped">
                <div class="control is-expanded">
                    <h1 class="title">Product</h1>
                </div>
                <p class="control">
                    <button class="button is-primary" @click="saveProduct">
                        Save
                    </button>
                </p>
            </div>

            <p v-if="errorMessage" class="help is-danger">{{ errorMessage }}</p>

            <div class="field">
                <label class="label">Name</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Product name..." v-model="product.name">
                </div>
            </div>

            <div class="field">
                <label class="label">Description</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Product description..." v-model="product.description">
                </div>
            </div>

            <div class="field is-grouped mt-5">
                <h1 class="title">Features</h1>

                <p class="control mt-1 ml-3">
                    <button class="button is-primary is-small is-rounded p-3" @click="addFeature">
                        <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                    </button>
                </p>
            </div>

            <div class="table-container">
                <table class="table is-fullwidth has-top-border has-bottom-border mt-0">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody v-if="product.features && product.features.length > 0">
                        <tr v-for="(feature, index) in product.features" :key="'feature' + index">
                            <td> {{ feature.id }}</td>

                            <td>
                                <div class="field">
                                    <div class="control">
                                        <input class="input table-input is-small" type="text" placeholder="Feature name..." v-model="feature.name">
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div class="field">
                                    <div class="control">
                                        <input class="input table-input is-small" type="text" placeholder="Feature description..." v-model="feature.description">
                                    </div>
                                </div>
                            </td>

                            <td>
                                <button class="button is-white is-inverted is-small" @click="removeFeature(index)">
                                    <font-awesome-icon :icon="['fa', 'trash-alt']" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div v-if="!product.features || product.features.length === 0" class="has-text-centered">
                    <p>No Features Found</p>
                </div>
            </div>

            <div class="field is-grouped is-grouped-right mt-5">
                <p class="control">
                    <button class="button is-primary" @click="saveProduct">
                        Save
                    </button>
                </p>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import { secureGet, securePost, securePut } from '../secureFetch.js';

    export default {
        name: 'Product',
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            }
        },
        data() {
            return {
                isLoading: false,
                productId: null,
                product: {
                    name: "",
                    desription: "",
                    features: []
                },
                errorMessage: null,
                defaultErrorMessage: "Something didn't work."
            }
        },
        mounted() {
            this.productId = this.$route.params.productId;
            this.getProduct();
        },
        methods: {
            getProduct() {
                if (this.productId) {
                    this.isLoading = true;

                    secureGet(this.hostUrl + "v1/product/" + this.productId)
                        .then(data => {
                            this.isLoading = false;

                            if (data && data.success) {
                                this.product = data.product;
                            }
                        });
                }
            },
            addFeature() {
                if (!this.product.features) {
                    this.product.features = [];
                }

                this.product.features.push({
                    name: "",
                    desription: ""
                });
            },
            removeFeature(featureIndex) {
                if (this.product && this.product.features) {
                    this.product.features.splice(featureIndex, 1);
                }
            },
            saveProduct() {
                this.isLoading = true;

                if (this.productId) {
                    securePut(this.hostUrl + "v1/product/" + this.productId, this.product)
                        .then((data) => {
                            this.isLoading = false;

                            if (data.success) {
                                this.product = data.product;
                            } else {
                                this.errorMessage = data.message;

                                if (!this.errorMessage) {
                                    this.errorMessage = this.defaultErrorMessage;
                                }
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
                else {
                    securePost(this.hostUrl + "v1/product/create-product", this.product)
                        .then((data) => {
                            this.isLoading = false;

                            if (data.success) {
                                this.product = data.product;
                                this.productId = data.product.id;
                            } else {
                                this.errorMessage = data.message;

                                if (!this.errorMessage) {
                                    this.errorMessage = this.defaultErrorMessage;
                                }
                            }
                        })
                        .catch((e) => {
                            this.isLoading = false;
                            this.errorMessage = this.defaultErrorMessage;
                            console.error(e);
                        });
                }
            }
        }
    };
</script>

<style scoped>
    td:last-child {
        width: 1em;
        padding: 0;
        white-space: nowrap;
    }
</style>